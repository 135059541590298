import { toInteger } from 'lodash'
import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'

/**
 * Hull Moving Average
 * @param this
 * @param series 序列
 * @param length 期數
 * @returns
 */
export function hma(
  this: ChartTypes.IndicatorThis,
  series: PineJS.NewVar<number>,
  length: number,
): number {
  //series.get(512)

  const wma1: number = this.wma(series, toInteger(length / 2))
  const wma2: number = this.wma(series, length)
  const ma: PineJS.NewVar<number> = this._context.new_var(2 * wma1 - wma2)

  return this.wma(ma, toInteger(this.PineJS.Std.sqrt(length)))
}
