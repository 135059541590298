import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { SessionType } from '~/modules/SDK/Chart2/SessionType'

interface GreatestSwing {
  longSwing: number
  shortSwing: number
}

/**
 * 計算Greatest Swing Value
 *
 * @param this
 * @param length 計算天數
 * @param sessionType 交易時段
 * @returns PineJS.NewVar<number>[]
 */
export function greatestSwing(
  this: ChartTypes.IndicatorThis,
  length: number,
  sessionType: SessionType,
): GreatestSwing {
  const dailyOpen: number[] = this.dailyOpen(length + 2, sessionType)
  const dailyHigh: number[] = this.dailyHigh(length + 2, sessionType)
  const dailyLow: number[] = this.dailyLow(length + 2, sessionType)

  const longSwingList: number[] = []
  const shortSwingList: number[] = []

  for (let i = 0; i < length; i++) {
    longSwingList.push(dailyHigh[i + 1] - dailyOpen[i + 1])

    shortSwingList.push(dailyOpen[i + 1] - dailyLow[i + 1])
  }

  return {
    longSwing: this.averageList(longSwingList),
    shortSwing: this.averageList(shortSwingList),
  }
}
