import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'

/**
 * 計算Array中的最小值
 * @param this
 * @param numberArray
 * @returns
 */
export function minList(this: ChartTypes.IndicatorThis, numberArray: number[]): number {
  let minValue = 0

  numberArray.forEach(num => {
    if (minValue === 0) {
      minValue = num
    } else if (num < minValue) {
      minValue = num
    }
  })

  return minValue
}
