import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'

/**
 * 計算序列資料的總和
 *
 * @param this
 * @param series 數列
 * @param length 期數
 * @returns
 */
export function summation(
  this: ChartTypes.IndicatorThis,
  series: PineJS.NewVar<number>,
  length: number,
): number {
  let sum = 0
  for (let i = 0; i < length; i++) {
    sum += series.get(i)
  }
  return sum
}
