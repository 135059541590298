import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { SessionType } from '~/modules/SDK/Chart2/SessionType'

interface Cdp {
  ah: number
  nh: number
  cdp: number
  nl: number
  al: number
}

/**
 * 計算當日CDP價位
 *
 * @param this
 * @param sessionType 交易時段
 * @returns Cdp
 */
export function cdp(this: ChartTypes.IndicatorThis, sessionType: SessionType): Cdp {
  const prevHigh = this.dailyHigh(3, sessionType)[1]
  const prevLow = this.dailyLow(3, sessionType)[1]
  const prevClose = this.dailyClose(3, sessionType)[1]

  const cdpValue = (prevHigh + prevLow + 2 * prevClose) * 0.25
  const ahValue = cdpValue + (prevHigh - prevLow)
  const nhValue = 2 * cdpValue - prevLow
  const nlValue = 2 * cdpValue - prevHigh
  const alValue = cdpValue - (prevHigh - prevLow)

  return {
    cdp: cdpValue,
    ah: ahValue,
    nh: nhValue,
    nl: nlValue,
    al: alValue,
  }
}
