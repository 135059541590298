import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { SessionType } from '~/modules/SDK/Chart2/SessionType'

interface OrbChannel {
  top: number
  bottom: number
}

/**
 * ORB Channel
 *
 * @param this
 * @param length 計算ORB的天數
 * @param fraction 通道大小乘數
 * @param sessionType 交易時段
 * @returns PineJS.NewVar<number>[ORB High, ORB Low]
 */
export function orbChannel(
  this: ChartTypes.IndicatorThis,
  length: number,
  fraction: number,
  sessionType: SessionType,
): OrbChannel {
  const dailyOpen: number[] = this.dailyOpen(length, sessionType)
  const openRange: number = this.openRange(length, sessionType)

  return { top: dailyOpen[0] + fraction * openRange, bottom: dailyOpen[0] - fraction * openRange }
}
