import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'

interface KeltnerChannel {
  mid: number
  top: number
  bottom: number
}
/**
 * Keltner Channel
 *
 * @param this
 * @param length 期數
 * @param atrs ATR倍數
 * @returns PineJS.NewVar<number>[上通道, 下通道線, SMA]
 */
export function keltnerChannel(
  this: ChartTypes.IndicatorThis,
  length: number,
  atrs: number,
): KeltnerChannel {
  const sma = this.average(this.ohlc.closeArray, length)
  const keltnerHigh = sma + atrs * this.atr(length)
  const keltnerLow = sma - atrs * this.atr(length)
  return { top: keltnerHigh, bottom: keltnerLow, mid: sma }
}
