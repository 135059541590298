import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'

/**
 * Momentum index 計算數列指定期間的變動量
 * @param this
 * @param series 數列
 * @param length 期數
 * @returns number
 */
export function momentum(
  this: ChartTypes.IndicatorThis,
  series: PineJS.NewVar<number>,
  length: number,
): number {
  return series.get(0) - series.get(length)
}
