import { useEffect } from 'react'
import { useInterval } from 'react-use'
import { createStore } from '~/store/createStore'

/**
 * - 預計重構時取代 useIndicatorStore 或 usePerfStore
 * - 預計與 createIndicator 的底層幫助函式連動，記錄其 state 用於與 React UI 互動，例如進出場訊的「持倉狀態」狀態包於此
 */
export const useIndicatorStore2 = createStore<
  UseIndicatorState & {
    buy(): void
    closeAll(): void
    sell(): void
    useSubscribeWatch(params?: {
      /**
       * State 的更新速率（state 被更新，以觸發 React re-render）
       *
       * - 預設 1000ms
       */
      stateRefreshRate?: number
    }): void
  }
>((set, get) => {
  const stateRef: {
    current: UseIndicatorState
  } = {
    current: {
      position: 0,
    },
  }

  return {
    useSubscribeWatch(params) {
      // Connect to the store on mount, disconnect on unmount, catch state-changes in a reference
      // useEffect(() => useIndicatorStore2.subscribe(state => (stateRef.current.position = state.position)), [])

      useInterval(() => {
        set(state => {
          state.position = stateRef.current.position
        })
      }, params?.stateRefreshRate ?? 1000)
    },
    position: 0,
    buy() {
      stateRef.current.position = 1
    },
    closeAll() {
      stateRef.current.position = 0
    },
    sell() {
      stateRef.current.position = -1
    },
  }
})

type UseIndicatorState = {
  /** 數量 */
  position: number
}
