import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { SessionType } from '~/modules/SDK/Chart2/SessionType'

/**
 * 計算ORB通道值
 * @param this
 * @param length
 * @param sessionType
 * @returns PineJS.NewVar<number>
 */
export function openRange(
  this: ChartTypes.IndicatorThis,
  length: number,
  sessionType: SessionType,
): number {
  const dailyOpen: number[] = this.dailyOpen(length + 2, sessionType)
  const dailyHigh: number[] = this.dailyHigh(length + 2, sessionType)
  const dailyLow: number[] = this.dailyLow(length + 2, sessionType)

  const orbList: number[] = []

  for (let i = 0; i < length; i++) {
    orbList.push(
      this.minList([dailyOpen[i + 1] - dailyLow[i + 1], dailyHigh[i + 1] - dailyOpen[i + 1]]),
    )
  }
  return this.averageList(orbList)
}
