import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { SessionType } from '~/modules/SDK/Chart2/SessionType'

interface GreatestSwingChannel {
  top: number
  bottom: number
}

/**
 * @param this
 * @param length 計算天數
 * @param fraction 通道大小乘數
 * @param sessionType 交易時段
 * @returns PineJS.NewVar<number>[GS-High, GS-Low]
 */
export function greatestSwingChannel(
  this: ChartTypes.IndicatorThis,
  length: number,
  fraction: number,
  sessionType: SessionType,
): GreatestSwingChannel {
  const dailyOpen: number[] = this.dailyOpen(length, sessionType)

  const greatestSwing = this.greatestSwing(length, sessionType)
  return {
    top: dailyOpen[0] + fraction * greatestSwing.longSwing,
    bottom: dailyOpen[0] - fraction * greatestSwing.shortSwing,
  }
}
