import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'

/**
 * 計算number array平均
 * @param this
 * @param listToAverage array to average
 * @returns number
 */
export function averageList(this: ChartTypes.IndicatorThis, listToAverage: number[]): number {
  return this.summationList(listToAverage) / listToAverage.length
}
