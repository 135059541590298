import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { SessionType } from '~/modules/SDK/Chart2/SessionType'

/**
 * 計算當日均價
 *
 * @param this
 * @param sessionType 交易時段
 * @returns Number
 */
export function dayAvgPrice(this: ChartTypes.IndicatorThis, sessionType: SessionType): number {
  return this.intradayAverage(this.ohlc.closeArray, sessionType)
}
