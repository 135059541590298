import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'

export function adaptiveMovingAverage(
  this: ChartTypes.IndicatorThis,
  series: PineJS.NewVar<number>,
  length: number,
  fastestEnd: number,
  lowestEnd: number,
): number {
  const ama = this._context.new_var()
  const diff = this._context.new_var()

  series.get(length)
  diff.get(length)
  ama.get(length)

  diff.set(Math.abs(series.get(0) - series.get(1)))

  if (!isNaN(ama.get(length))) {
    const direction = series.get(0) - series.get(length)
    const volatility = this.summation(diff, length)
    const efficiencyRatio = volatility !== 0 ? Math.abs(direction / volatility) : 0

    const fastest = 2 / (fastestEnd + 1)
    const slowest = 2 / (lowestEnd + 1)

    const percentage = efficiencyRatio * (fastest - slowest) + slowest

    const amaValue = ama.get(1) + Math.pow(percentage, 2) * (series.get(0) - ama.get(1))
    ama.set(amaValue)
  } else {
    ama.set(series.get(0))
  }

  return ama.get(0)
}
