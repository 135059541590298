import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { SessionType } from '~/modules/SDK/Chart2/SessionType'
import { isSessionFirstBarV2 } from './isSessionFirstBar'

/**
 * 計算日內特定數列最小值
 *
 * @param this
 * @param series 數列
 * @param sessionType 交易時段
 * @returns Number
 */
export function intradayLowest(
  this: ChartTypes.IndicatorThis,
  series: PineJS.NewVar<number>,
  sessionType: SessionType,
): number {
  return this.lowest(series, this.intradayCurrentBar(sessionType))
}

export function intradayLowestV2(
  this: ChartTypes.IndicatorThis,
  ll: PineJS.NewVar<number>,
  tt: PineJS.NewVar<number>,
  sessionType: SessionType,
) {
  for (let i = 0; i < Math.min(ll.mindepth, tt.mindepth); i++) {
    if (isSessionFirstBarV2(tt.get(i), tt.get(i + 1), this._context.symbol.period, sessionType)) {
      return this.PineJS.Std.lowest(ll, i + 1, this._context)
    }
  }
  return NaN
}
