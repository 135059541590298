import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'

interface DonchianChannel {
  top: number
  bottom: number
}

/**
 * @param this
 * @param length: number 期數
 * @returns DonchianChannel
 */
export function donchianChannel(this: ChartTypes.IndicatorThis, length: number): DonchianChannel {
  const high = this.ohlc.highArray
  const low = this.ohlc.lowArray
  return {
    top: this.highest(this.ohlc.highArray, length),
    bottom: this.lowest(this.ohlc.lowArray, length),
  }
}
