import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'

interface BollingerBand {
  top: number
  bottom: number
  mid: number
}

/**
 * BBand
 *
 * @param this
 * @param series 數列
 * @param length 期數
 * @param stds 標準差倍數
 * @returns number[上通道, 下通道, SMA]
 */
export function bollingerBand(
  this: ChartTypes.IndicatorThis,
  series: PineJS.NewVar<number>,
  length: number,
  stds: number,
): BollingerBand {
  series.get(length)
  const sma = this.average(series, length)
  const standardDev = this.standardDev(series, length)
  return { top: sma + stds * standardDev, bottom: sma - stds * standardDev, mid: sma }
}
