import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'

/**
 * 加權平均收盤價
 */
export function weightedClose(this: ChartTypes.IndicatorThis) {
  const high: PineJS.NewVar<number> = this.ohlc.highArray
  const low: PineJS.NewVar<number> = this.ohlc.lowArray
  const close: PineJS.NewVar<number> = this.ohlc.closeArray
  return (2 * close.get(0) + high.get(0) + low.get(0)) * 0.25
}
