import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'

/**
 * 計算數列的母體標準差
 *
 * @param this
 * @param series 數列
 * @param length 期數
 * @returns number
 */
export function standardDev(
  this: ChartTypes.IndicatorThis,
  series: PineJS.NewVar<number>,
  length: number,
): number {
  series.get(length)
  const variance = this.variance(series, length)
  return variance > 0 ? Math.sqrt(variance) : 0
}
