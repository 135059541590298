import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'

/** Directional Movement Index return `'DI+' 'DI-' 'ADX'`*/
export function dmi(this: ChartTypes.IndicatorThis, di_Length: number, adx_Smoothing: number) {
  const f_0 = function (e: any) {
    return -e
  }
  const f_1 = (e: any, t: any) => {
    return this.PineJS.Std.and(this.PineJS.Std.gt(e, t), this.PineJS.Std.gt(e, 0)) ? e : 0
  }
  const f_2 = function (e: any, t: any) {
    return (100 * e) / t
  }
  const f_3 = function (e: any, t: any) {
    return e + t
  }
  const f_4 = (e: any, t: any, i: any) => {
    return this.PineJS.Std.abs(e - t) / (this.PineJS.Std.eq(i, 0) ? 1 : i)
  }
  const f_5 = function (e: any) {
    return 100 * e
  }

  const i = di_Length
  const r = adx_Smoothing
  const o = this.PineJS.Std.high(this._context)
  const s = this._context.new_var(o)
  const a = this.PineJS.Std.change(s)
  const l = this.PineJS.Std.low(this._context)
  const c = this._context.new_var(l)
  const u = this.PineJS.Std.change(c)
  const h = f_0(u)
  const d = this.PineJS.Std.tr(this._context)
  const p = this._context.new_var(d)
  const f = this.PineJS.Std.rma(p, i, this._context)
  const _ = f_1(a, h)
  const m = this._context.new_var(_)
  const v = this.PineJS.Std.rma(m, i, this._context)
  const y = f_2(v, f)
  const g = this.PineJS.Std.fixnan(y, this._context)
  const b = f_1(h, a)
  const S = this._context.new_var(b)
  const w = this.PineJS.Std.rma(S, i, this._context)
  const P = f_2(w, f)
  const C = this.PineJS.Std.fixnan(P, this._context)
  const T = f_3(g, C)
  const x = f_4(g, C, T)
  const I = this._context.new_var(x)
  const L = this.PineJS.Std.rma(I, r, this._context)

  return [g, C, f_5(L)]
}
