import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'

/**
 * RSI
 * @param this
 * @param series 序列
 * @param length 期數
 * @returns number
 */
export function rsi(
  this: ChartTypes.IndicatorThis,
  series: PineJS.NewVar<number>,
  length: number,
): number {
  let rsiValue = 0

  const sumUp: PineJS.NewVar<number> = this._context.new_var()
  const sumDown: PineJS.NewVar<number> = this._context.new_var()

  if (series.hist_pos === 1) {
    sumUp.set(this.maxList([series.get(0) - series.get(1), 0]))
    sumDown.set(this.maxList([series.get(1) - series.get(0), 0]))
  } else {
    const up: number = this.maxList([series.get(0) - series.get(1), 0])
    const down: number = this.maxList([series.get(1) - series.get(0), 0])

    sumUp.set(sumUp.get(1) + (up - sumUp.get(1)) / length)
    sumDown.set(sumDown.get(1) + (down - sumDown.get(1)) / length)
  }

  if (isNaN(sumUp.get(0))) {
    sumUp.set(0)
  }

  if (isNaN(sumDown.get(0))) {
    sumDown.set(0)
  }

  if (sumUp.get(0) + sumDown.get(0) === 0) {
    rsiValue = 0
  } else {
    rsiValue = (100 * sumUp.get(0)) / (sumUp.get(0) + sumDown.get(0))
  }
  return rsiValue
}
