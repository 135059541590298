import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { SessionType } from '~/modules/SDK/Chart2/SessionType'

/**
 * 計算日內特定數列平均
 * @param this
 * @param series 數列
 * @param sessionType 交易時段
 * @returns number
 */
export function intradayAverage(
  this: ChartTypes.IndicatorThis,
  series: PineJS.NewVar<number>,
  sessionType: SessionType,
): number {
  // const intradayCurrentBar: number = this.intradayCurrentBar(sessionType)
  // return this.average(series, intradayCurrentBar)

  return this.intradaySummation(series, sessionType) / this.intradayCurrentBar(sessionType)
}
