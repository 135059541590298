import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { SessionType } from '~/modules/SDK/Chart2/SessionType'

/**
 * 計算當日成交量加權均價
 * @param this
 * @param sessionType 交易時段
 * @returns PineJS.NewVar<number>
 */
export function vwap(this: ChartTypes.IndicatorThis, sessionType: SessionType): number {
  const avgPrice: number = this.avgPrice()
  const volume: PineJS.NewVar<number> = this._context.new_var(this.PineJS.Std.volume(this._context))
  // volume.get(512)
  volume.get(1)
  const tradeValue: PineJS.NewVar<number> = this._context.new_var(avgPrice * volume.get(0))
  // tradeValue.get(512)
  tradeValue.get(1)

  const tradeValueSum: number = this.intradaySummation(tradeValue, sessionType)
  const volumeSum: number = this.intradaySummation(volume, sessionType)

  return tradeValueSum / volumeSum
}
