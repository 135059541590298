import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'

/**
 * 計算Array中的最大值
 * @param this
 * @param numberArray
 * @returns
 */
export function maxList(this: ChartTypes.IndicatorThis, numberArray: number[]): number {
  let maxValue = 0

  numberArray.forEach(num => {
    if (maxValue === 0) {
      maxValue = num
    } else if (num > maxValue) {
      maxValue = num
    }
  })

  return maxValue
}
