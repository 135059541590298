import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'

/**
 * 計算序列的母體變異數
 *
 * @param this
 * @param series 序列
 * @param length 期數
 * @returns number
 */
export function variance(
  this: ChartTypes.IndicatorThis,
  series: PineJS.NewVar<number>,
  length: number,
): number {
  series.get(length)

  let varianceValue = 0

  if (length > 0) {
    const avg = this.average(series, length)
    let sum = 0

    for (let i = 0; i < length; i++) {
      sum += Math.pow(series.get(i) - avg, 2)
    }

    varianceValue = sum / length
  }

  return varianceValue
}
