import { css, SerializedStyles } from '@emotion/react'
import { rwdrc2 } from '~/configs/rwdrc'

export const createIPadCss = (rootCss: SerializedStyles) =>
  css`
    @media screen and (min-width: ${rwdrc2.tablet.min + 'px'}) and (max-width: ${rwdrc2.tablet.max +
      'px'}) {
      ${rootCss}
    }
  `
