import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'

/** [下階梯線:`當值`, 上階梯線:`當值`,下階梯線:`前一值`, 上階梯線:`前一值`, 中梯線:`當值`, 中梯線:`前值`] */
export function ladderChannl(this: ChartTypes.IndicatorThis, channlLength: number) {
  const high_array = this.ohlc.highArray
  const low_array = this.ohlc.lowArray
  const length = channlLength
  const r = this.PineJS.Std.low(this._context)
  const o = this._context.new_var(r)
  const lowest = this.PineJS.Std.lowest(o, length, this._context)
  const a = this.PineJS.Std.high(this._context)
  const l = this._context.new_var(a)
  const heighest = this.PineJS.Std.highest(l, length, this._context)

  const up_line = this._context.new_var()
  const dn_line = this._context.new_var()
  const mid_line = this._context.new_var()
  const plot_flag = this._context.new_var()

  if (high_array.get(0) >= heighest) {
    plot_flag.set(1)
  }
  if (low_array.get(0) <= lowest) {
    plot_flag.set(-1)
  }

  if (plot_flag.get(0) === 1) {
    up_line.set(NaN)
    dn_line.set(lowest)
    mid_line.set((heighest + lowest) / 2)
  } else if (plot_flag.get(0) === -1) {
    up_line.set(heighest)
    dn_line.set(NaN)
    mid_line.set((heighest + lowest) / 2)
  }

  dn_line.get(2)
  up_line.get(2)
  mid_line.get(2)
  plot_flag.get(2)

  return [
    dn_line.get(0),
    up_line.get(0),
    dn_line.get(1),
    up_line.get(1),
    mid_line.get(0),
    mid_line.get(1),
  ]
}
