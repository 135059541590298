import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'

export function isSettlementDay(this: ChartTypes.IndicatorThis) {
  const interval = this.PineJS.Std.interval(this._context)
  //日期
  const time = this.PineJS.Std.time(this._context)
  //日期處理
  const getDay = new Date(time).getDay()
  const getMonth = new Date(time).getMonth()
  //月份比較
  const monthCompare = this._context.new_var()
  //每月幾號
  const MonthOfDay = new Date(time).getDate()
  //小時、分鐘
  const start_h = new Date(time).getHours()
  const start_m = new Date(time).getMinutes()
  //月結算的收盤時間 13:30
  const marketCloseTime =
    interval !== 60 ? start_h === 13 && start_m === 45 - interval : start_h === 12 && start_m === 45
  //月結算的標記
  const mark = this._context.new_var()
  //儲存當日月份
  monthCompare.set(getMonth)
  //換月時將結算標記歸零
  if (monthCompare.get(0) !== monthCompare.get(1)) {
    mark.set(0)
  }
  //月結算邏輯 , 當月結Mark = 0 時才會判斷
  const checkDay3 =
    mark.get(0) === 0 && getDay === 3 && MonthOfDay >= 15 && MonthOfDay <= 21 && marketCloseTime
  const checkDay4 =
    mark.get(0) === 0 && getDay === 4 && MonthOfDay >= 16 && MonthOfDay <= 22 && marketCloseTime
  const checkDay5 =
    mark.get(0) === 0 && getDay === 5 && MonthOfDay >= 17 && MonthOfDay <= 23 && marketCloseTime
  const checkDay1 =
    mark.get(0) === 0 && getDay === 1 && MonthOfDay >= 20 && MonthOfDay <= 26 && marketCloseTime
  const checkDay2 =
    mark.get(0) === 0 && getDay === 2 && MonthOfDay >= 21 && MonthOfDay <= 27 && marketCloseTime
  const isCheckDay = checkDay3 || checkDay4 || checkDay5 || checkDay1 || checkDay2
  //如果是月結算 月結Mark標記為1 ,然後將在下次換月時 Mark標記才會歸零
  if (isCheckDay) {
    mark.set(1)
  }
  //true/false
  //console.log(marketCloseTime)
  if (isCheckDay) return true
  else return false
}
