import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { SessionType } from '~/modules/SDK/Chart2/SessionType'

/**
 * 取得日內目前K棒數量(第幾跟)
 *
 * @param this
 * @param sessionType 交易時段
 * @returns number
 */
export function intradayCurrentBar(
  this: ChartTypes.IndicatorThis,
  sessionType: SessionType,
): number {
  const currentBar: PineJS.NewVar<number> = this._context.new_var()
  currentBar.get(1)

  if (isNaN(currentBar.get(0))) {
    currentBar.set(1)
  }

  if (this.isSessionFirstBar(sessionType)) {
    currentBar.set(1)
  } else {
    currentBar.set(currentBar.get(0) + 1)
  }

  return currentBar.get(0)
}
