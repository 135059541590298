import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'

/**
 * 取得當根K棒的均價 (Open + High + Low + Close) / 4
 * @param this
 * @returns number
 */
export function avgPrice(this: ChartTypes.IndicatorThis): number {
  const high: PineJS.NewVar<number> = this.ohlc.highArray
  const low: PineJS.NewVar<number> = this.ohlc.lowArray
  const close: PineJS.NewVar<number> = this.ohlc.closeArray
  const open: PineJS.NewVar<number> = this.ohlc.openArray
  return (high.get(0) + low.get(0) + open.get(0) + close.get(0)) * 0.25
}
