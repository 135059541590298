import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { SessionType } from '~/modules/SDK/Chart2/SessionType'

interface StrongWeakGate {
  strongPrice: number
  weakPrice: number
}

/**
 * 日內的強弱關價
 *
 * @param this
 * @param sessionType 交易時段
 * @returns Number[strongGate, weakGate]
 */
export function strongWeakGate(
  this: ChartTypes.IndicatorThis,
  sessionType: SessionType,
): StrongWeakGate {
  const dayHigh = this.intradayHighest(this.ohlc.highArray, sessionType)
  const dayLow = this.intradayLowest(this.ohlc.lowArray, sessionType)
  const strongPrice = dayLow + (dayHigh - dayLow) * 0.618
  const weakPrice = dayLow + (dayHigh - dayLow) * 0.382
  return {
    strongPrice: strongPrice,
    weakPrice: weakPrice,
  }
}
