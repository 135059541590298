import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'

/**
 * Volume Weighted Moving Average
 * @param this
 * @param length 期數
 * @returns
 */
export function vwma(this: ChartTypes.IndicatorThis, length: number) {
  const avgPrice: number = this.avgPrice()
  const volume: PineJS.NewVar<number> = this._context.new_var(this.PineJS.Std.volume(this._context))

  const tradeValue: PineJS.NewVar<number> = this._context.new_var(avgPrice * volume.get(0))
  const tradeValueSum: number = this.summation(tradeValue, length)
  const volumeSum: number = this.summation(volume, length)

  return tradeValueSum / volumeSum
}
