import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'

/**
 * 收盤價與均線的乖離程度
 * @param this
 * @param length 期數
 * @returns number
 */
export function bias(this: ChartTypes.IndicatorThis, length: number): number {
  const close: PineJS.NewVar<number> = this.ohlc.closeArray
  close.get(100)

  const sma: number = this.average(close, length)
  const biasValue = ((close.get(0) - sma) / sma) * 100
  return biasValue
}
