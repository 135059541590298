import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'

/**
 * 威廉指標
 * @param this
 * @param length: number 期數
 * @returns number
 */
export function percentR(this: ChartTypes.IndicatorThis, length: number): number {
  const high: PineJS.NewVar<number> = this.ohlc.highArray
  const low: PineJS.NewVar<number> = this.ohlc.lowArray
  const close: PineJS.NewVar<number> = this.ohlc.closeArray

  const varA: number = this.highest(high, length)
  const varB: number = varA - this.lowest(low, length)

  let pctR: number

  if (varB !== 0) {
    pctR = 100 - ((varA - close.get(0)) / varB) * 100
  } else {
    pctR = 0
  }

  return pctR
}
