import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'

export function isBarChanging(this: ChartTypes.IndicatorThis) {
  const localTimeVar = this._context.new_var()
  const timeDeltaMs = 500
  const isBarChanging_ =
    isNaN(localTimeVar.get(1)) || Math.abs(new Date().getTime() - localTimeVar.get(1)) < timeDeltaMs
  localTimeVar.set(new Date().getTime())
  return isBarChanging_
}
