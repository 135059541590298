import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'

//變形macd
export function ok1788customized2(
  this: ChartTypes.IndicatorThis,
  length_ma: number,
  length_tower: number,
) {
  const close = this.ohlc.close

  const close_array = this.ohlc.closeArray
  const open_array = this.ohlc.openArray
  const low_array = this.ohlc.lowArray
  const high_array = this.ohlc.highArray

  open_array.get(1)
  close_array.get(1)

  const highest = this.PineJS.Std.highest(high_array, length_tower, this._context)
  const lowest = this.PineJS.Std.lowest(low_array, length_tower, this._context)
  const highest_array = this._context.new_var(highest)
  const lowest_array = this._context.new_var(lowest)
  const color_flag = this._context.new_var()
  const sma = this.PineJS.Std.sma(close_array, length_ma, this._context)
  const displayLineUP = this._context.new_var()
  const displayLineDN = this._context.new_var()

  highest_array.get(1)
  lowest_array.get(1)

  if (close >= highest_array.get(1)) {
    color_flag.set(1)
  }
  if (close <= lowest_array.get(1)) {
    color_flag.set(-1)
  }

  const colorIndex = color_flag.get(0) === 1 ? 1 : -1

  if (close - sma >= 0) {
    displayLineUP.set(close - sma)
    displayLineDN.set(NaN)
  } else if (close - sma <= 0) {
    displayLineUP.set(NaN)
    displayLineDN.set(close - sma)
  }

  return [displayLineUP.get(0), displayLineDN.get(0)]
}
