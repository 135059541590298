import { max } from 'lodash'
import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '../../Chart2/PineJS'

//sar多單停損線
/**
 * type: `線性/非線性` ,
 * strength: 強度`1 ~ 10` ,
 * position: `0 | 1 | -1` ,
 * lenght: `期間天數` ,
 * stdv: `標準差` ,
 * AFStep: `初始值` ,
 * AFLimit: `極值`
 */
export function sarLongStopLoss(
  this: ChartTypes.IndicatorThis,
  type: 'linear' | 'nonLinear',
  strength: number,
  marketPosition: PineJS.NewVar<number>,
  length: number,
  stdv: number,
  AFStep: number,
  AFLimit: number,
) {
  const avgTrueRange = this.avgTrueRange(length)
  const high = this.PineJS.Std.high(this._context)
  const low = this.PineJS.Std.low(this._context)

  //sar停損線----start
  const vh = this._context.new_var()
  const vl = this._context.new_var()
  const vtick = this._context.new_var()
  const limit = this._context.new_var()
  vh.get(1)
  vl.get(1)

  //部位發生變化時重設變數
  if (marketPosition.get(0) === 1 && marketPosition.get(1) !== 1) {
    vtick.set(AFStep)
    vh.set(high)
    vl.set(low - avgTrueRange.get(0) * stdv)
  }

  //持有多單時監聽
  if (marketPosition.get(0) === 1) {
    //創高時
    if (high > vh.get(0)) {
      vh.set(high)
    }

    //停損線新值
    const value = type === 'nonLinear' ? vh.get(0) - vl.get(0) : 1
    vl.set(vl.get(0) + vtick * value)

    //vtick未達到AFLimit
    const condition = vh.get(0) > vh.get(1) && vtick < AFLimit
    if (condition) {
      vtick.set(vtick.get(0) + (max([vtick.get(0), limit]) ?? 0) / (10 - strength + 1))
    }
  }

  //無多單部位不給數值
  if (marketPosition.get(0) !== 1) {
    vl.set(NaN)
  }

  return vl
}
