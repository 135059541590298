import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'

//變形macd
export function ok1788customized1(this: ChartTypes.IndicatorThis, length: number) {
  const c = this.PineJS.Std.close(this._context)
  const nc = this._context.new_var(c)

  const ma1 = this.PineJS.Std.ema(nc, length, this._context)
  const ma2 = this.PineJS.Std.sma(nc, length, this._context)

  const diff = ma1 - ma2

  return diff
}
