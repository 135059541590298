import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { SessionType } from '~/modules/SDK/Chart2/SessionType'
import { isSessionFirstBarV2 } from './isSessionFirstBar'

/**
 * 計算日內特定數列最大值
 *
 * @param this
 * @param series 數列
 * @param sessionType 交易時段
 * @returns Number
 */
export function intradayHighest(
  this: ChartTypes.IndicatorThis,
  series: PineJS.NewVar<number>,
  sessionType: SessionType,
): number {
  const intradayCurrentBar: number = this.intradayCurrentBar(sessionType)
  return this.highest(series, intradayCurrentBar)
}

export function intradayHighestV2(
  this: ChartTypes.IndicatorThis,
  hh: PineJS.NewVar<number>,
  tt: PineJS.NewVar<number>,
  sessionType: SessionType,
) {
  for (let i = 0; i < Math.min(hh.mindepth, tt.mindepth); i++) {
    if (isSessionFirstBarV2(tt.get(i), tt.get(i + 1), this._context.symbol.period, sessionType)) {
      return this.PineJS.Std.highest(hh, i + 1, this._context)
    }
  }
  return NaN
}
