import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { SessionType } from '~/modules/SDK/Chart2/SessionType'

interface PivotPoint {
  pivot: number
  resistant1: number
  resistant2: number
  resistant3: number
  support1: number
  support2: number
  support3: number
}

/**
 * Pivot Point
 *
 * @param this
 * @param sessionType 交易時段
 * @returns PineJS.NewVar<number>[壓力3, 壓力2, 壓力1, PivotPoint, 支撐1, 支撐2, 支撐3]
 */
export function pivotPoint(this: ChartTypes.IndicatorThis, sessionType: SessionType): PivotPoint {
  const prevHigh: number = this.dailyHigh(5, sessionType)[1]
  const prevLow: number = this.dailyLow(5, sessionType)[1]
  const prevClose: number = this.dailyClose(5, sessionType)[1]

  const pivotPointValue: number = (prevHigh + prevLow + prevClose) / 3
  const r3: number = prevHigh + 2 * (pivotPointValue - prevLow)
  const r2: number = pivotPointValue + prevHigh - prevLow
  const r1: number = 2 * pivotPointValue - prevLow
  const s1: number = 2 * pivotPointValue - prevHigh
  const s2: number = pivotPointValue - prevHigh + prevLow
  const s3: number = prevLow - 2 * (prevHigh - pivotPointValue)
  return {
    pivot: pivotPointValue,
    resistant1: r1,
    resistant2: r2,
    resistant3: r3,
    support1: s1,
    support2: s2,
    support3: s3,
  }
}
