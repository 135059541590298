import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { SessionType } from '~/modules/SDK/Chart2/SessionType'

/**
 *
 * @param this
 * @param length 天數
 * @param sessionType 交易時段
 * @returns
 */
export function dualThrustRange(
  this: ChartTypes.IndicatorThis,
  length: number,
  sessionType: SessionType,
): number {
  const dailyHigh: number[] = this.dailyHigh(length + 2, sessionType)
  const dailyLow: number[] = this.dailyLow(length + 2, sessionType)
  const dailyClose: number[] = this.dailyClose(length + 2, sessionType)

  const highArray: number[] = []
  const lowArray: number[] = []
  const closeArray: number[] = []

  for (let i = 0; i < length; i++) {
    highArray.push(dailyHigh[i + 1])
    lowArray.push(dailyLow[i + 1])
    closeArray.push(dailyClose[i + 1])
  }

  const highestHigh: number = this.maxList(highArray)
  const highestClose: number = this.maxList(closeArray)
  const lowestLow: number = this.minList(lowArray)
  const lowestClose: number = this.minList(closeArray)

  return this.maxList([highestHigh - lowestClose, highestClose - lowestLow])
}
