import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { SessionType } from '~/modules/SDK/Chart2/SessionType'

/**
 * 取得日線的最低價
 *
 * @param this
 * @param daysBack 期別
 * @param sessionType 交易時段
 * @returns number[]
 */
export function dailyLow(
  this: ChartTypes.IndicatorThis,
  daysBack: number,
  sessionType: SessionType,
): number[] {
  const low: PineJS.NewVar<number> = this.ohlc.lowArray
  low.get(4096)

  const dayLowArray: PineJS.NewVar<number> = this._context.new_var()
  dayLowArray.set(this.intradayLowest(low, sessionType))
  const prevLow: PineJS.NewVar<number> = this._context.new_var()
  dayLowArray.get(4096)
  prevLow.get(4096)

  if (this.isSessionLastBar(sessionType)) {
    prevLow.set(dayLowArray.get(0))
  }

  const dailyLowArray: number[] = []

  for (let i = 0; i < daysBack; i++) {
    if (i === 0) {
      dailyLowArray.push(dayLowArray.get(i))
    } else {
      dailyLowArray.push(prevLow.get(i))
    }
  }

  return dailyLowArray
}
