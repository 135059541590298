import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'

/**
 * Commodity Channel Index
 * @param this
 * @param length 期數
 * @returns number
 */
export function cci(this: ChartTypes.IndicatorThis, length: number): number {
  const high: PineJS.NewVar<number> = this.ohlc.highArray
  const low: PineJS.NewVar<number> = this.ohlc.lowArray
  const close: PineJS.NewVar<number> = this.ohlc.closeArray

  const priceSum: PineJS.NewVar<number> = this._context.new_var(
    high.get(0) + low.get(0) + close.get(0),
  )
  const avgTp: PineJS.NewVar<number> = this._context.new_var(this.average(priceSum, length))

  let sumDist = 0
  let cciValue = 0

  for (let i = 0; i < length; i++) {
    sumDist = sumDist + Math.abs(avgTp.get(i) - priceSum.get(i))
  }

  sumDist = sumDist / length

  if (sumDist !== 0) {
    cciValue = (high.get(0) + low.get(0) + close.get(0) - avgTp.get(0)) / (0.015 * sumDist)
  } else {
    cciValue = 0
  }
  return cciValue
}
