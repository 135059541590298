import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { SessionType } from '~/modules/SDK/Chart2/SessionType'

/**
 * 計算日內特定數列加總
 *
 * @param this
 * @param series 數列
 * @param sessionType 交易時段
 * @returns number
 */
export function intradaySummation(
  this: ChartTypes.IndicatorThis,
  series: PineJS.NewVar<number>,
  sessionType: SessionType,
): number {
  const result: PineJS.NewVar<number> = this._context.new_var()

  if (this.isSessionFirstBar(sessionType)) {
    result.set(series.get(0))
  } else {
    result.set(result.get(0) + series.get(0))
  }
  return result.get(0)
}
