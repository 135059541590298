import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'

/**
 * Typical price
 * @param this
 * @returns number
 */
export function typicalPrice(this: ChartTypes.IndicatorThis): number {
  const high: PineJS.NewVar<number> = this.ohlc.highArray
  const low: PineJS.NewVar<number> = this.ohlc.lowArray
  const close: PineJS.NewVar<number> = this.ohlc.closeArray
  return (high.get(0) + low.get(0) + close.get(0)) / 3
}
